<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col xs="12" lg="12">
        <b-card>
          <div><h4 class="d-inline"><i class="fa fa-users"></i> All Project Managers</h4>
          <router-link v-if="$session.get('user') == 'accountant'" class="float-right btn btn-success" :to="{ name: 'Accountant Add Project Manager' }">Add Project Manager <i class="fa fa-plus"></i></router-link>
          <router-link v-else class="float-right btn btn-success" :to="{ name: 'Manager Add Project Manager' }">Add Project Manager <i class="fa fa-plus"></i></router-link></div>
          <hr class="clearfix my-4">
          <v-client-table :columns="columns" :data="wtsData" :options="options" :theme="theme" class="no-overflow" id="dataTable">
            <div slot="status" slot-scope="props">
              <span v-if="props.row.status === 0">
                <button class="btn btn-sm btn-success w-100">Active</button>
              </span>
              <span v-if="props.row.status !== 0">
                <button class="btn btn-sm btn-danger w-100">Inactive</button>
              </span>
            </div>
            <span slot="name" slot-scope="props"><router-link :to="{ name: 'Company', params: { id: props.row.companyid } }">{{props.row.name}}</router-link></span>

            <span slot="creation_date" slot-scope="props">{{ props.row.creation_date | moment("M/D/YYYY") }}</span>

            <div slot="assignments" slot-scope="props">
              <b-button v-if="props.row.status === 0" size="sm" v-b-modal.assignmentsWts variant="secondary" @click="findAssignments(props.row.wtsid)"><i class="fa fa-eye"></i> View Assignments </b-button>
            </div>
            <div slot="actions" slot-scope="props">
              <b-dropdown text="Actions" size="sm" left class="m-md-2" variant="primary">
                <b-dropdown-item-button v-b-modal.assignWts @click="sendInfo(props.row.wtsid)">Assign To Project</b-dropdown-item-button>
                <b-dropdown-item-button v-if="$session.get('level') === 1" v-b-modal.editWts @click="sendInfo(props.row.wtsid)">Edit Profile</b-dropdown-item-button>
                <b-dropdown-item-button v-if="$session.get('level') === 1" v-b-modal.passwordWts @click="sendInfo(props.row.wtsid)">Change Password</b-dropdown-item-button>
              </b-dropdown>
            </div>
          </v-client-table>
        </b-card>
      </b-col>
    </b-row>
    <b-modal @shown="clearErrors" id="editWts" ref="editModal" title="Edit Project Manager" title-tag="h2" centered size="lg" hide-footer>
      <form @submit.prevent="validateBeforeSubmit" class="pb-2">
        <label for="">First Name</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="text" v-validate="'required'" name="fname" v-model="fname" class="form-control" placeholder="First Name" />
        </b-input-group>
        <i v-show="errors.has('fname')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('fname')" class="help is-danger">The first name is required.</span>

        <label for="">Last Name</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="text" v-validate="'required'" name="lname" v-model="lname" class="form-control" placeholder="Last Name" />
        </b-input-group>
        <i v-show="errors.has('lname')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('lname')" class="help is-danger">The last name is required.</span>

        <hr>
        <label for="">Phone Number</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-phone fa-flip-horizontal"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="number" v-model="phone" v-validate="'required|digits:10'" name="phone" class="form-control" placeholder="Phone Number" />
        </b-input-group>
        <i v-show="errors.has('phone')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('phone')" class="help is-danger">{{ errors.first('phone') }}</span>

        <label for="">Fax Number</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-fax"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="text" v-model="fax" v-validate="'digits:10'" name="fax" class="form-control" placeholder="Fax Number" />
        </b-input-group>
        <i v-show="errors.has('fax')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('fax')" class="help is-danger">{{ errors.first('fax') }}</span>


        <hr>
        <label for="">Email</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-envelope"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="email" v-model="email" v-validate="'required'" name="email" class="form-control" placeholder="Email" />
        </b-input-group>
        <i v-show="errors.has('email')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('email')" class="help is-danger">The email is required.</span>

        <b-input-group class="mt-3 mb-2">
          <b-form-checkbox class="ml-2 mt-1" v-model="status" value="0" unchecked-value="1"> <p class="ml-3 mb-0">Account <span v-if="status == 0">Active</span><span v-if="status != 0"> Inactive</span></p></b-form-checkbox>
        </b-input-group>

        <i v-show="error" class="fa fa-exclamation-triangle mt-3"></i><span v-show="error" class="help is-danger">Error. Please contact admin. </span><span v-show="unique" class="help is-danger">Email already exists.</span>
        <b-button variant="success" class="mt-4" type="submit" block>Save</b-button>
      </form>
    </b-modal>
    <b-modal @shown="clearErrors" id="assignWts" ref="assignModal" title="Assign Project Manager" title-tag="h2" centered size="lg" hide-footer>
      <form @submit.prevent="assignvalidateBeforeSubmit" class="pb-2">
        <label for="">Project</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend style="z-index: 99;">
            <b-input-group-text>
              <i class="fa fa-cog"></i>
            </b-input-group-text>
          </b-input-group-prepend>
          <select class="select-account form-control" name="projectid" v-model="projectid">
            <option value="" disabled selected>Select Project</option>
            <option for v-for="option in projectData" v-bind:value="option.projectid">{{option.projectname}}</option>
          </select>
        </b-input-group>

        <!-- <label for="">Approval Limit</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text style="padding: 0 15px;"><i class="fa fa-dollar-sign"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="text" v-validate="'required'" name="passlimit" v-model="passlimit" class="form-control" placeholder="Approval Limit" />
        </b-input-group> -->

        <i v-show="assignerror" class="fa fa-exclamation-triangle mt-3"></i><span v-show="assignerror" class="help is-danger">Assign Error. Cannot have duplicates. Please contact admin.</span></span>
        <b-button variant="success" class="mt-4" type="submit" block>Create Assignment</b-button>
      </form>
    </b-modal>
    <b-modal @shown="clearErrors" id="passwordWts" ref="editPasswordModal" title="Edit Project Manager Password" title-tag="h2" centered size="lg" hide-footer>
      <form @submit.prevent="passwordvalidateBeforeSubmit" class="pb-2">
        <p class="text-muted">Enter password and confirmation</p>
        <b-row class="mb-3">
          <b-col md="12 mb-2">
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-lock"></i></b-input-group-text>
              </b-input-group-prepend>
              <input type="password" class="form-control" v-model="password" placeholder="Password" name="password" ref="password"/>
            </b-input-group>
          </b-col>
          <b-col md="12">
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-lock"></i></b-input-group-text>
              </b-input-group-prepend>
              <input type="password" class="form-control" v-model="password2" placeholder="Password Confirmation" data-vv-as="password" name="password2" />
            </b-input-group>
          </b-col>
        </b-row>
        <i v-show="passworderror" class="fa fa-exclamation-triangle"></i><span v-show="passworderror" class="help is-danger">Error. Weak password or do not match. Try again.</span>

        <b-button variant="success" class="mt-4" type="submit" block>Edit Password</b-button>
      </form>
    </b-modal>
    <b-modal @shown="clearErrors" id="assignmentsWts" ref="assignmentmodal" title="Project Manager Assignments" title-tag="h2" centered size="lg" hide-footer>
      <table class="table">
        <thead>
          <th>Project Name</th>
          <!-- <th>Approval Limit</th> -->
          <th>Status</th>
          <th>Edit</th>
        </thead>
        <tr v-for="assignment in wtsFilteredData">
          <td>{{assignment.projectname}}</td>
          <!-- <td>${{assignment.passlimit}}</td> -->
          <td>
            <span v-if="assignment.status === 0">
              <button class="btn btn-sm btn-success w-100">Active</button>
            </span>
            <span v-if="assignment.status !== 0">
              <button class="btn btn-sm btn-danger w-100">Inactive</button>
            </span>
          </td>
          <td>
            <b-button class="btn-sm" v-b-modal.editassignmentWts @click="sendEditInfo(assignment.wpid)">Edit <i class="fa fa-edit"></i></b-button>
          </td>
        </tr>
      </table>
    </b-modal>
    <b-modal @shown="clearErrors" id="editassignmentWts" ref="editassignmentmodal" title="Edit Project Manager Assignment" title-tag="h2" centered size="lg" hide-footer>
      <form @submit.prevent="editassignmentvalidateBeforeSubmit" class="pb-2">
        <label for="">Project</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text>
              <i class="fa fa-cog"></i>
            </b-input-group-text>
          </b-input-group-prepend>
          <select class="select-account form-control" name="editprojectid" v-model="editprojectid">
            <option value="" disabled selected>Select Project</option>
            <option for v-for="option in projectData"  v-bind:value="option.projectid">{{option.projectname}}</option>
          </select>
        </b-input-group>

        <!-- <label for="">Approval Limit</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text style="padding: 0 15px;"><i class="fa fa-dollar-sign"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="text" name="editpasslimit" v-model="editpasslimit" class="form-control" placeholder="Approval Limit" />
        </b-input-group> -->

        <b-input-group class="mt-3 mb-2">
          <b-form-checkbox class="ml-2 mt-1" v-model="editstatus" value="0" unchecked-value="1"><p class="ml-3 mb-0"><span v-if="editstatus == 0">Active</span><span v-if="editstatus != 0"> Inactive</span></p></b-form-checkbox>
        </b-input-group>

        <i v-show="editassignerror" class="fa fa-exclamation-triangle mt-3"></i><span v-show="editassignerror" class="help is-danger">Assign Error. Cannot have duplicate assignments.</span></span>
        <b-button variant="success" class="mt-4" type="submit" block>Edit Assignment</b-button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import {ClientTable, Event} from 'vue-tables-2'
Vue.use(ClientTable)

export default {
  name: 'ManagerAssignWts',
  components: {
    ClientTable,
    Event,
  },
  data () {
    return {
      columns: ['status', 'email', 'fname', 'lname', 'fax', 'phone', 'creation_date', 'assignments', 'actions'],
      wtsData: [],
      options: {
        headings: {
          companyid: 'Company ID',
          name: 'Company Name',
          fname: 'First Name',
          lname: 'Last Name',
          check: '',
        },
        sortable: ['companyid', 'email', 'fname', 'lname', 'status', 'fax', 'phone', 'creation_date'],
        filterable: ['companyid', 'email', 'fname', 'lname', 'status', 'fax', 'phone', 'creation_date'],
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        pagination: {
          chunk: 5,
          edge: false,
          nav: 'scroll'
        }
      },
      theme: 'bootstrap4',
      template: 'default',
      useVuex: false,
      fname: "",
      lname: "",
      email: "",
      status: "",
      fax: "",
      phone: "",
      password: "",
      password2: "",
      id: "",
      error: false,
      passworderror: false,
      projectData: [],
      projectid: '',
      unique: false,
      assignerror: false,
      passlimit:0,
      wtsAssignmentData: [],
      wtsFilteredData: [],
      editprojectid: "",
      editwtsid: "",
      editstatus: "",
      editpasslimit: "",
      edit: "",
      editid: "",
      editassignerror: false
    }
  },
  methods: {
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = JSON.stringify({
            id: this.id,
            fname: this.fname,
            lname: this.lname,
            email: this.email,
            status: this.status,
            fax: this.fax,
            phone: this.phone,
          })
          this.$http.post('/manager/edit/wts', data)
          .then(response => {
            if (response.status === 200) {
              this.$toasted.show('Project Manager Updated Successfully', {type: 'success', duration: '3000'})
              this.$refs.editModal.hide();
              this.loadData();
            }
          })
          .catch(error => {
            if(error.response.data.details == "email_UNIQUE must be unique"){
              this.unique = true;
            }
            this.$toasted.show('Error', {type: 'error', duration: '3000'})
            this.error = true;
          })
          return;
        }
      });
    },
    passwordvalidateBeforeSubmit() {
      let data = JSON.stringify({
        id: this.id,
        password: this.password,
        confirm_password: this.password2,
      })
      this.$http.post('/manager/edit/wts_password', data)
      .then(response => {
        if (response.status === 200) {
          this.$toasted.show('Password Updated', {icon: 'fa-check', type: 'success', duration: '3000'})
          this.$refs.editPasswordModal.hide();
        }
      })
      .catch(error => {
        this.passworderror = true;
      })
      return;
    },
    assignvalidateBeforeSubmit() {
      let data = JSON.stringify({
        projectid: this.projectid,
        wtsid: this.id,
        passlimit: this.passlimit,
      })
      this.$http.post('/manager/create/wts_project', data)
      .then(response => {
        if (response.status === 200) {
          this.$toasted.show('Assignment Created', {icon: 'fa-check', type: 'success', duration: '3000'})
          this.$refs.assignModal.hide();
        }
      })
      .catch(error => {
        this.assignerror = true;
      })
      return;
    },
    editassignmentvalidateBeforeSubmit() {
      let data = JSON.stringify({
        id: this.editid,
        projectid: this.editprojectid,
        wtsid: this.editwtsid,
        passlimit: this.editpasslimit,
        status: this.editstatus
      })
      this.$http.post('/manager/edit/wts_project', data)
      .then(response => {
        if (response.status === 200) {
          this.$toasted.show('Assignment Edited', {icon: 'fa-check', type: 'success', duration: '3000'})
          this.$refs.editassignmentmodal.hide();
        }
      })
      .catch(error => {
        this.editassignerror = true;
      })
      return;
    },
    sendInfo(id) {
      false;
      var val = id;
      let data = this.wtsData;
      var id = data.findIndex(function(item, i){
        return item.wtsid === val
      });

      this.id = this.wtsData[id]['wtsid'];
      this.fname = this.wtsData[id]['fname'];
      this.lname = this.wtsData[id]['lname'];
      this.email = this.wtsData[id]['email'];
      this.status = this.wtsData[id]['status'];
      this.fax = this.wtsData[id]['fax'];
      this.phone = this.wtsData[id]['phone'];
    },
    sendEditInfo(id) {
      var val = id;
      let data = this.wtsFilteredData;
      var id = data.findIndex(function(item, i){
        return item.wpid === val
      });

      this.editid = this.wtsFilteredData[id].wpid;
      this.editprojectid = this.wtsFilteredData[id].projectid;
      this.editwtsid = this.wtsFilteredData[id].wtsid;
      this.editstatus = this.wtsFilteredData[id].status;
      this.editpasslimit = this.wtsFilteredData[id].passlimit;
    },
    findAssignments(id) {
      this.$http.get('/manager/get/wts_projects')
      .then(response => {
        if (response.status === 200) {
          this.wtsAssignmentData = response.data.result;
          this.wtsFilteredData = this.wtsAssignmentData.filter(o => o.wtsid === id);
        }
      })
      .catch(error => {
        this.$toasted.show('Error.', {type: 'error', duration: '3000'})
      })
    },
    clearErrors() {
      this.error = false;
      this.assignerror = false;
      this.editassignerror = false;
    },
    loadData() {
      this.$http.get('/manager/get/wtses')
      .then(response => {
        this.wtsData = response.data.result;
      })
      .catch(error => {
        this.$toasted.show('Error.', {type: 'error', duration: '3000'})
      })
      this.$http.get('/manager/get/projects')
      .then(response => {
        this.projectData = response.data.result;
      })
      .catch(error => {
        this.$toasted.show('Error.', {type: 'error', duration: '3000'})
      })
    }
  },
  mounted: function () {
    this.loadData();
  },
}
</script>

<style lang="scss">
  label {
    display:block !important;
  }
</style>
