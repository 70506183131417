var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { xs: "12", lg: "12" } },
            [
              _c(
                "b-card",
                [
                  _c(
                    "div",
                    [
                      _c("h4", { staticClass: "d-inline" }, [
                        _c("i", { staticClass: "fa fa-users" }),
                        _vm._v(" All Project Managers")
                      ]),
                      _vm.$session.get("user") == "accountant"
                        ? _c(
                            "router-link",
                            {
                              staticClass: "float-right btn btn-success",
                              attrs: {
                                to: { name: "Accountant Add Project Manager" }
                              }
                            },
                            [
                              _vm._v("Add Project Manager "),
                              _c("i", { staticClass: "fa fa-plus" })
                            ]
                          )
                        : _c(
                            "router-link",
                            {
                              staticClass: "float-right btn btn-success",
                              attrs: {
                                to: { name: "Manager Add Project Manager" }
                              }
                            },
                            [
                              _vm._v("Add Project Manager "),
                              _c("i", { staticClass: "fa fa-plus" })
                            ]
                          )
                    ],
                    1
                  ),
                  _c("hr", { staticClass: "clearfix my-4" }),
                  _c("v-client-table", {
                    staticClass: "no-overflow",
                    attrs: {
                      columns: _vm.columns,
                      data: _vm.wtsData,
                      options: _vm.options,
                      theme: _vm.theme,
                      id: "dataTable"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "status",
                        fn: function(props) {
                          return _c("div", {}, [
                            props.row.status === 0
                              ? _c("span", [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-success w-100"
                                    },
                                    [_vm._v("Active")]
                                  )
                                ])
                              : _vm._e(),
                            props.row.status !== 0
                              ? _c("span", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-sm btn-danger w-100"
                                    },
                                    [_vm._v("Inactive")]
                                  )
                                ])
                              : _vm._e()
                          ])
                        }
                      },
                      {
                        key: "name",
                        fn: function(props) {
                          return _c(
                            "span",
                            {},
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "Company",
                                      params: { id: props.row.companyid }
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(props.row.name))]
                              )
                            ],
                            1
                          )
                        }
                      },
                      {
                        key: "creation_date",
                        fn: function(props) {
                          return _c("span", {}, [
                            _vm._v(
                              _vm._s(
                                _vm._f("moment")(
                                  props.row.creation_date,
                                  "M/D/YYYY"
                                )
                              )
                            )
                          ])
                        }
                      },
                      {
                        key: "assignments",
                        fn: function(props) {
                          return _c(
                            "div",
                            {},
                            [
                              props.row.status === 0
                                ? _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName: "v-b-modal.assignmentsWts",
                                          modifiers: { assignmentsWts: true }
                                        }
                                      ],
                                      attrs: {
                                        size: "sm",
                                        variant: "secondary"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.findAssignments(
                                            props.row.wtsid
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("i", { staticClass: "fa fa-eye" }),
                                      _vm._v(" View Assignments ")
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        }
                      },
                      {
                        key: "actions",
                        fn: function(props) {
                          return _c(
                            "div",
                            {},
                            [
                              _c(
                                "b-dropdown",
                                {
                                  staticClass: "m-md-2",
                                  attrs: {
                                    text: "Actions",
                                    size: "sm",
                                    left: "",
                                    variant: "primary"
                                  }
                                },
                                [
                                  _c(
                                    "b-dropdown-item-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName: "v-b-modal.assignWts",
                                          modifiers: { assignWts: true }
                                        }
                                      ],
                                      on: {
                                        click: function($event) {
                                          return _vm.sendInfo(props.row.wtsid)
                                        }
                                      }
                                    },
                                    [_vm._v("Assign To Project")]
                                  ),
                                  _vm.$session.get("level") === 1
                                    ? _c(
                                        "b-dropdown-item-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName: "v-b-modal.editWts",
                                              modifiers: { editWts: true }
                                            }
                                          ],
                                          on: {
                                            click: function($event) {
                                              return _vm.sendInfo(
                                                props.row.wtsid
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Edit Profile")]
                                      )
                                    : _vm._e(),
                                  _vm.$session.get("level") === 1
                                    ? _c(
                                        "b-dropdown-item-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName: "v-b-modal.passwordWts",
                                              modifiers: { passwordWts: true }
                                            }
                                          ],
                                          on: {
                                            click: function($event) {
                                              return _vm.sendInfo(
                                                props.row.wtsid
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Change Password")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "editModal",
          attrs: {
            id: "editWts",
            title: "Edit Project Manager",
            "title-tag": "h2",
            centered: "",
            size: "lg",
            "hide-footer": ""
          },
          on: { shown: _vm.clearErrors }
        },
        [
          _c(
            "form",
            {
              staticClass: "pb-2",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.validateBeforeSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("First Name")]),
              _c(
                "b-input-group",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-input-group-prepend",
                    [
                      _c("b-input-group-text", [
                        _c("i", { staticClass: "fa fa-user" })
                      ])
                    ],
                    1
                  ),
                  _c("b-form-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "fname",
                      placeholder: "First Name"
                    },
                    model: {
                      value: _vm.fname,
                      callback: function($$v) {
                        _vm.fname = $$v
                      },
                      expression: "fname"
                    }
                  })
                ],
                1
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("fname"),
                    expression: "errors.has('fname')"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("fname"),
                      expression: "errors.has('fname')"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [_vm._v("The first name is required.")]
              ),
              _c("label", { attrs: { for: "" } }, [_vm._v("Last Name")]),
              _c(
                "b-input-group",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-input-group-prepend",
                    [
                      _c("b-input-group-text", [
                        _c("i", { staticClass: "fa fa-user" })
                      ])
                    ],
                    1
                  ),
                  _c("b-form-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "lname",
                      placeholder: "Last Name"
                    },
                    model: {
                      value: _vm.lname,
                      callback: function($$v) {
                        _vm.lname = $$v
                      },
                      expression: "lname"
                    }
                  })
                ],
                1
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("lname"),
                    expression: "errors.has('lname')"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("lname"),
                      expression: "errors.has('lname')"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [_vm._v("The last name is required.")]
              ),
              _c("hr"),
              _c("label", { attrs: { for: "" } }, [_vm._v("Phone Number")]),
              _c(
                "b-input-group",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-input-group-prepend",
                    [
                      _c("b-input-group-text", [
                        _c("i", {
                          staticClass: "fa fa-phone fa-flip-horizontal"
                        })
                      ])
                    ],
                    1
                  ),
                  _c("b-form-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|digits:10",
                        expression: "'required|digits:10'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "number",
                      name: "phone",
                      placeholder: "Phone Number"
                    },
                    model: {
                      value: _vm.phone,
                      callback: function($$v) {
                        _vm.phone = $$v
                      },
                      expression: "phone"
                    }
                  })
                ],
                1
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("phone"),
                    expression: "errors.has('phone')"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("phone"),
                      expression: "errors.has('phone')"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [_vm._v(_vm._s(_vm.errors.first("phone")))]
              ),
              _c("label", { attrs: { for: "" } }, [_vm._v("Fax Number")]),
              _c(
                "b-input-group",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-input-group-prepend",
                    [
                      _c("b-input-group-text", [
                        _c("i", { staticClass: "fa fa-fax" })
                      ])
                    ],
                    1
                  ),
                  _c("b-form-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "digits:10",
                        expression: "'digits:10'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "fax",
                      placeholder: "Fax Number"
                    },
                    model: {
                      value: _vm.fax,
                      callback: function($$v) {
                        _vm.fax = $$v
                      },
                      expression: "fax"
                    }
                  })
                ],
                1
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("fax"),
                    expression: "errors.has('fax')"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("fax"),
                      expression: "errors.has('fax')"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [_vm._v(_vm._s(_vm.errors.first("fax")))]
              ),
              _c("hr"),
              _c("label", { attrs: { for: "" } }, [_vm._v("Email")]),
              _c(
                "b-input-group",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-input-group-prepend",
                    [
                      _c("b-input-group-text", [
                        _c("i", { staticClass: "fa fa-envelope" })
                      ])
                    ],
                    1
                  ),
                  _c("b-form-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "email",
                      name: "email",
                      placeholder: "Email"
                    },
                    model: {
                      value: _vm.email,
                      callback: function($$v) {
                        _vm.email = $$v
                      },
                      expression: "email"
                    }
                  })
                ],
                1
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("email"),
                    expression: "errors.has('email')"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("email"),
                      expression: "errors.has('email')"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [_vm._v("The email is required.")]
              ),
              _c(
                "b-input-group",
                { staticClass: "mt-3 mb-2" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "ml-2 mt-1",
                      attrs: { value: "0", "unchecked-value": "1" },
                      model: {
                        value: _vm.status,
                        callback: function($$v) {
                          _vm.status = $$v
                        },
                        expression: "status"
                      }
                    },
                    [
                      _c("p", { staticClass: "ml-3 mb-0" }, [
                        _vm._v("Account "),
                        _vm.status == 0
                          ? _c("span", [_vm._v("Active")])
                          : _vm._e(),
                        _vm.status != 0
                          ? _c("span", [_vm._v(" Inactive")])
                          : _vm._e()
                      ])
                    ]
                  )
                ],
                1
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.error,
                    expression: "error"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle mt-3"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.error,
                      expression: "error"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [_vm._v("Error. Please contact admin. ")]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.unique,
                      expression: "unique"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [_vm._v("Email already exists.")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mt-4",
                  attrs: { variant: "success", type: "submit", block: "" }
                },
                [_vm._v("Save")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "assignModal",
          attrs: {
            id: "assignWts",
            title: "Assign Project Manager",
            "title-tag": "h2",
            centered: "",
            size: "lg",
            "hide-footer": ""
          },
          on: { shown: _vm.clearErrors }
        },
        [
          _c(
            "form",
            {
              staticClass: "pb-2",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.assignvalidateBeforeSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Project")]),
              _c(
                "b-input-group",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-input-group-prepend",
                    { staticStyle: { "z-index": "99" } },
                    [
                      _c("b-input-group-text", [
                        _c("i", { staticClass: "fa fa-cog" })
                      ])
                    ],
                    1
                  ),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.projectid,
                          expression: "projectid"
                        }
                      ],
                      staticClass: "select-account form-control",
                      attrs: { name: "projectid" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.projectid = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    [
                      _c(
                        "option",
                        { attrs: { value: "", disabled: "", selected: "" } },
                        [_vm._v("Select Project")]
                      ),
                      _vm._l(_vm.projectData, function(option) {
                        return _c(
                          "option",
                          {
                            attrs: { for: "" },
                            domProps: { value: option.projectid }
                          },
                          [_vm._v(_vm._s(option.projectname))]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.assignerror,
                    expression: "assignerror"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle mt-3"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.assignerror,
                      expression: "assignerror"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [
                  _vm._v(
                    "Assign Error. Cannot have duplicates. Please contact admin."
                  )
                ]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mt-4",
                  attrs: { variant: "success", type: "submit", block: "" }
                },
                [_vm._v("Create Assignment")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "editPasswordModal",
          attrs: {
            id: "passwordWts",
            title: "Edit Project Manager Password",
            "title-tag": "h2",
            centered: "",
            size: "lg",
            "hide-footer": ""
          },
          on: { shown: _vm.clearErrors }
        },
        [
          _c(
            "form",
            {
              staticClass: "pb-2",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.passwordvalidateBeforeSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c("p", { staticClass: "text-muted" }, [
                _vm._v("Enter password and confirmation")
              ]),
              _c(
                "b-row",
                { staticClass: "mb-3" },
                [
                  _c(
                    "b-col",
                    { attrs: { md: "12 mb-2" } },
                    [
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c("b-input-group-text", [
                                _c("i", { staticClass: "fa fa-lock" })
                              ])
                            ],
                            1
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.password,
                                expression: "password"
                              }
                            ],
                            ref: "password",
                            staticClass: "form-control",
                            attrs: {
                              type: "password",
                              placeholder: "Password",
                              name: "password"
                            },
                            domProps: { value: _vm.password },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.password = $event.target.value
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c("b-input-group-text", [
                                _c("i", { staticClass: "fa fa-lock" })
                              ])
                            ],
                            1
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.password2,
                                expression: "password2"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "password",
                              placeholder: "Password Confirmation",
                              "data-vv-as": "password",
                              name: "password2"
                            },
                            domProps: { value: _vm.password2 },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.password2 = $event.target.value
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.passworderror,
                    expression: "passworderror"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.passworderror,
                      expression: "passworderror"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [_vm._v("Error. Weak password or do not match. Try again.")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mt-4",
                  attrs: { variant: "success", type: "submit", block: "" }
                },
                [_vm._v("Edit Password")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "assignmentmodal",
          attrs: {
            id: "assignmentsWts",
            title: "Project Manager Assignments",
            "title-tag": "h2",
            centered: "",
            size: "lg",
            "hide-footer": ""
          },
          on: { shown: _vm.clearErrors }
        },
        [
          _c(
            "table",
            { staticClass: "table" },
            [
              _c("thead", [
                _c("th", [_vm._v("Project Name")]),
                _c("th", [_vm._v("Status")]),
                _c("th", [_vm._v("Edit")])
              ]),
              _vm._l(_vm.wtsFilteredData, function(assignment) {
                return _c("tr", [
                  _c("td", [_vm._v(_vm._s(assignment.projectname))]),
                  _c("td", [
                    assignment.status === 0
                      ? _c("span", [
                          _c(
                            "button",
                            { staticClass: "btn btn-sm btn-success w-100" },
                            [_vm._v("Active")]
                          )
                        ])
                      : _vm._e(),
                    assignment.status !== 0
                      ? _c("span", [
                          _c(
                            "button",
                            { staticClass: "btn btn-sm btn-danger w-100" },
                            [_vm._v("Inactive")]
                          )
                        ])
                      : _vm._e()
                  ]),
                  _c(
                    "td",
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.editassignmentWts",
                              modifiers: { editassignmentWts: true }
                            }
                          ],
                          staticClass: "btn-sm",
                          on: {
                            click: function($event) {
                              return _vm.sendEditInfo(assignment.wpid)
                            }
                          }
                        },
                        [
                          _vm._v("Edit "),
                          _c("i", { staticClass: "fa fa-edit" })
                        ]
                      )
                    ],
                    1
                  )
                ])
              })
            ],
            2
          )
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "editassignmentmodal",
          attrs: {
            id: "editassignmentWts",
            title: "Edit Project Manager Assignment",
            "title-tag": "h2",
            centered: "",
            size: "lg",
            "hide-footer": ""
          },
          on: { shown: _vm.clearErrors }
        },
        [
          _c(
            "form",
            {
              staticClass: "pb-2",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.editassignmentvalidateBeforeSubmit.apply(
                    null,
                    arguments
                  )
                }
              }
            },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Project")]),
              _c(
                "b-input-group",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-input-group-prepend",
                    [
                      _c("b-input-group-text", [
                        _c("i", { staticClass: "fa fa-cog" })
                      ])
                    ],
                    1
                  ),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.editprojectid,
                          expression: "editprojectid"
                        }
                      ],
                      staticClass: "select-account form-control",
                      attrs: { name: "editprojectid" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.editprojectid = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    [
                      _c(
                        "option",
                        { attrs: { value: "", disabled: "", selected: "" } },
                        [_vm._v("Select Project")]
                      ),
                      _vm._l(_vm.projectData, function(option) {
                        return _c(
                          "option",
                          {
                            attrs: { for: "" },
                            domProps: { value: option.projectid }
                          },
                          [_vm._v(_vm._s(option.projectname))]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "b-input-group",
                { staticClass: "mt-3 mb-2" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "ml-2 mt-1",
                      attrs: { value: "0", "unchecked-value": "1" },
                      model: {
                        value: _vm.editstatus,
                        callback: function($$v) {
                          _vm.editstatus = $$v
                        },
                        expression: "editstatus"
                      }
                    },
                    [
                      _c("p", { staticClass: "ml-3 mb-0" }, [
                        _vm.editstatus == 0
                          ? _c("span", [_vm._v("Active")])
                          : _vm._e(),
                        _vm.editstatus != 0
                          ? _c("span", [_vm._v(" Inactive")])
                          : _vm._e()
                      ])
                    ]
                  )
                ],
                1
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.editassignerror,
                    expression: "editassignerror"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle mt-3"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editassignerror,
                      expression: "editassignerror"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [_vm._v("Assign Error. Cannot have duplicate assignments.")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mt-4",
                  attrs: { variant: "success", type: "submit", block: "" }
                },
                [_vm._v("Edit Assignment")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }